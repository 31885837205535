import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Image, Text, Skeleton, SkeletonText, VStack, Progress, Badge, useColorModeValue, HStack, Button, Spacer, Flex, useDisclosure, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'
import { IoMdPricetag } from 'react-icons/io'
import { useCountUp } from 'use-count-up'

import ActiveLink from '../ActiveLink'
import Translate from '../Translate'
import SetTrailer from './SetTrailer'

function Set({ data, loaded, toBuy }) {

  const [imgLoaded, setImgLoaded] = useState(false)
  const [last, setLast] = useState(0)

  const setBg = useColorModeValue('gray.50', 'gray.600')

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { title = '', id, slug, progress = 0, image, description, added_date, roadmap_name, price, promotion } = data

  const handleImageLoad = () => {
    if (!imgLoaded) setImgLoaded(true)
  }

  const { value: progressValue } = useCountUp({
    isCounting: true,
    end: progress,
    duration: 0.5,
    onComplete: () => {
      if (last === 0) setLast(progress)
    }
  })

  return (
    <>
      <ActiveLink to={`/platform/set/${slug}`} isActive={Number.isInteger(id) && !toBuy}>
        <Box { ...(toBuy ? { onClick: onOpen } : {})} background={setBg} borderRadius={2} boxShadow='base' _hover={{ boxShadow: 'lg' }} transition={'.2s'} cursor='pointer' position='relative'>
          <Skeleton isLoaded={imgLoaded}>
            <Image h={{ base: 124, lg: 168 }} src={image} onLoad={handleImageLoad} bg='gray.200' width='100%' objectFit='cover' />
          </Skeleton>
          <Box pos='absolute' h={{ base: 124, lg: 168 }} w='100%' bg='rgba(0,0,0,0)' top={0}></Box>
          <VStack spacing={2} px={4} pt={4} pb={6} align='flex-start' >
            {
              loaded ?
                <>
                  <Heading as='h4' size='md' textTransform='uppercase'>
                    { title }
                    { (added_date + (3600 * 24 * 30)) > (Date.now() / 1000) ? <Badge ml={2} variant="subtle" colorScheme="green"><Translate>NEW</Translate></Badge> : null }
                    { promotion ? <Badge ml={2} variant="solid" colorScheme="red"><Translate>PROMOTION!</Translate> -{promotion.percentage}%</Badge> : null }
                  </Heading>
                  { roadmap_name ? <Tag size='md'variant="solid" colorScheme="teal">{ roadmap_name }</Tag> : null }
                  <Text fontSize='.9em'>{ description.split(' ').splice(0,20).join(' ') + '...' }</Text>
                </>
                :
                <>
                  <Skeleton noOfLines={1} w={'100%'}></Skeleton>
                  <SkeletonText w={'100%'} noOfLines={5}></SkeletonText>
                </>
            }
            {
              toBuy ?
                <Flex w='100%'>
                  { Number.isInteger(id) ?
                    <Button leftIcon={<MdSearch />} variant='brand-solid' size='sm' w='calc(100% - 130px)'>
                      <Translate>See details</Translate>
                    </Button>
                    : null }
                  <Spacer />
                  {
                    price ? <Tag
                      size='lg'
                      borderRadius="full"
                      colorScheme={promotion ? 'red' : ''}
                    >
                      <TagLeftIcon boxSize="20px" as={IoMdPricetag} />
                      <TagLabel variant="brand-solid">{ price.brutto.formatted }</TagLabel>
                    </Tag>
                      : null
                  }
                </Flex>
                :
                <HStack w={'100%'} align='center'>
                  <Progress colorScheme="brand" size="lg" value={progressValue} w='100%' />
                  <Text>{ progressValue + '%' }</Text>
                </HStack>
            }
          </VStack>
        </Box>
      </ActiveLink>
      { Number.isInteger(id) ? <SetTrailer isOpen={isOpen} onClose={onClose} data={data} /> : null }
    </>
  )
}

Set.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    progress: PropTypes.integer,
    slug: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
    added_date: PropTypes.number,
    roadmap_name: PropTypes.string,
    price: PropTypes.shape({
      brutto: PropTypes.shape({
        formatted: PropTypes.string,
        bare: PropTypes.number
      }),
      tax: PropTypes.number
    }),
    promotion: PropTypes.shape({
      percentage: PropTypes.number,
      oldprice: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([ null ])])
    })
  }),
  loaded: PropTypes.bool,
  toBuy: PropTypes.bool
}

export default Set

