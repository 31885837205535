import React from 'react'
import PropTypes from 'prop-types'
import { Box, Center, Text, Heading, Link, useBreakpointValue } from '@chakra-ui/react'

import Translate from '../components/Translate'
import Logo from '../components/Logo'
import AuthForm from '../components/auth/AuthForm'

function Login({ form, authorize }) {

  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box w="100%" display={{ base: 'block', md: 'flex' }}>
      <Box display={{ base: 'none', md: 'block' }} w='30%' bg='brand.400' color='white' p={4} boxShadow='lg'>
        <Center h='100%'>
          <Logo src='logo-100' color='light'/>
        </Center>
      </Box>
      <Center px={{ base: 10, md: 20, lg: 100, xl: 200 }} w={{ base: '100%', md: '70%' }} minH="100vh">
        <Box textAlign='center' w='100%'>
          {
            isMobile ?
              <Box>
                <Center h='100%'>
                  <Logo src='logo-100'/>
                </Center>
              </Box>
              : null
          }

          <Box py={10}>
            {
              form === 'login' && authorize ?
                <Heading as='h3'><Translate>Sign in to authorize action</Translate></Heading>
                : form === 'login' ?
                  <Heading as='h3'><Translate>Sign in</Translate></Heading>
                  : <Heading as='h3'><Translate>Sign up</Translate></Heading>
            }
          </Box>

          <AuthForm form={form} authorize={authorize} />
          <Text my={4}>
            &copy; <Link href='https://lifesense.pl' isExternal>
              <Translate>Life Sense Foundation</Translate>
            </Link> 2021</Text>
        </Box>
      </Center>
    </Box>
  )
}

Login.propTypes = {
  form: PropTypes.string,
  authorize: PropTypes.bool
}

export default Login
