import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation, Link as RouteLink } from 'react-router-dom'
import { Box, Stack, Button } from '@chakra-ui/react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useAuth } from '../../utils/auth'
import Translate from '../Translate'
import Alert from '../Alert'
import SubmitButton from '../buttons/SubmitButton'
import LoginAuthForm from './LoginAuthForm'
import RegisterAuthForm from './RegisterAuthForm'
import { apiFetch } from '../../utils/api'

function AuthForm({ form, authorize }) {

  const auth = useAuth()
  const location = useLocation()
  const history = useHistory()
  const api = apiFetch(null)

  const get_params = new URLSearchParams(location.search)

  const [errorsState, setErrorsState] = useState({})
  const [notification, setNotification] = useState(null)
  const [agreements, setAgreements] = useState(null)

  const [formState, setFormState] = useState({
    login: {
      values: { email: '', password: '' }
    },
    register: {
      values: { firstname: '', lastname: '', email: '', password: '', repeatPassword: '', 'agreement-rules': false, 'agreement-gdpr': false }
    }
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    // http://localhost:3000/authorize?response_type=bearer&client_id=abcd&scope=admin&redirect_uri=lifesense-app%3A%2F%2Fauthorize-api
    if (location.pathname === '/authorize') {
      let wasErrored = false
      const requiredKeys = ['client_id', 'response_type', 'scope', 'redirect_uri']

      requiredKeys.forEach((key) => {
        if (!get_params.has(key)) {
          history.replace({ to: { pathname: '/sign-in'}, from: location.state })

          wasErrored = true

          setNotification({
            type: 'error', message: <Translate>There are missing keys for authorize endpoint.</Translate>
          })
        }
      })

      if (!wasErrored) {
        sessionStorage.setItem('authorize-content', location.search)

        history.replace({ to: { pathname: '/authorize'}, from: location.state })
      }
    }

    if (!agreements) {
      api('/get-register-agreements').then((agreements) => {
        setAgreements(agreements)
      })
        .catch(() => {})
    }
  }, [])

  const handleSubmit = async (e, turnOffLoading) => {
    e.preventDefault()

    if (!executeRecaptcha) return

    const recaptcha = await executeRecaptcha('authorization')

    api(form === 'login' ? '/login' : '/register', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ 'g-recaptcha-response': recaptcha, ...formState[form].values })
    })
      .then((rest) => {

        const { token } = rest

        if (rest.status === 201) {
          turnOffLoading()

          setNotification({
            type: 'success', message: <Translate>Account created! Please confirm your account by clicking on the link in the email. It will be active for the next 48 hours.</Translate>
          })
        }
        else if (rest.status === 200) {

          auth.login(token, function() {
            let { from } = location.state || { from: { pathname: authorize ? '/grant-access' + location.search : '/platform' } }
            history.replace(from)
          })
        }
      })
      .catch(async (rest) => {

        try {
          const body = await rest.json()
          setErrorsState(body.errors)
        }
        catch(err) {
          setErrorsState({})
        }

        setNotification(null)
        turnOffLoading()

        switch(rest.status) {
        case 400 :
          setNotification({
            type: 'error', message: form === 'register' ? <Translate>Fix all errors</Translate> : <Translate>Login failure</Translate>
          })
          break

        case 401 :
          setNotification({
            type: 'error', message: <Translate>Incorrect email or password</Translate>
          })
          break

        case 409 :
          setNotification({
            type: 'error', message: <Translate>Account already exists. Use another email address.</Translate>
          })
          break

        default :
          setNotification({
            type: 'error', message: <Translate>Internal API error</Translate>
          })
        }
      })
  }

  const handlePassInput = (type, field, event) => {
    setFormState({
      ...formState,
      [type]: {
        ...formState[type],
        values: {
          ...formState[type].values,
          [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }
      }
    })
  }

  return (
    <Box>
      <form className={`auth-form auth-form--${form}`} method='POST'>
        <Stack spacing='24px'>
          { notification ? <Alert { ...{ ...notification } }>{ notification.message }</Alert> : null }

          { form === 'login' ? <LoginAuthForm errors={errorsState} passInput={(f, e) => handlePassInput('login', f, e)} /> : <RegisterAuthForm agreements={agreements} errors={errorsState} passInput={(f, e) => handlePassInput('register', f, e)} /> }

          <Stack my={2} direction={{ base: 'column', md: 'row' }}>
            <SubmitButton isDisabled={form === 'register' && !agreements} onClick={handleSubmit} w={{ base: '100%', md: '50%', xl: '60%' }} variant='brand-solid'>
              {
                form === 'login' ?
                  <Translate>Sign in</Translate>
                  : <Translate>Sign up</Translate>
              }
            </SubmitButton>
            <Box w={{ base: '100%', md: '50%', xl: '40%' }}>
              {
                form === 'login'
                  ?
                  <RouteLink to={'/sign-up'} style={{ width: '100%' }}>
                    <Button isFullWidth variant='brand-ghost'>
                      <Translate>Don&apos;t have an account? Sign up</Translate>
                    </Button>
                  </RouteLink>
                  :
                  <RouteLink to={'/sign-in'} style={{ width: '100%' }}>
                    <Button isFullWidth variant='brand-ghost'>
                      <Translate>You have an account? Sign in</Translate>
                    </Button>
                  </RouteLink>
              }
            </Box>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}

AuthForm.propTypes = {
  form: PropTypes.string,
  authorize: PropTypes.bool
}

export default AuthForm
