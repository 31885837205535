import React from 'react'
import { VStack, Text, Heading } from '@chakra-ui/react'

import Translate from '../../components/Translate'

function Dashboard() {

  return (
    <VStack alignItems='flex-start' spacing={6}>
      <Heading as='h2' size='lg'>
        <Translate>Start</Translate>
      </Heading>
      <VStack spacing={2} alignItems='flex-start'>
        <Text>Witaj na Platformie Life Sense!</Text>
        <Text>Aby rozpocząć naukę, przejdź do zakładki Ścieżki.</Text>
      </VStack>
    </VStack>
  )
}

export default Dashboard
