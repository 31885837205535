import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDisclosure, createStandaloneToast, VStack, Box, Heading, Text, useColorModeValue, Spacer, Flex, Button, useBreakpointValue, Editable, EditableInput, EditablePreview, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { MdAdd, MdSave } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import SetEditorContext from '../../contexts/SetEditorContext'
import useStateWithCallback from '../../utils/useStateWithCallback'
import { useApiFetch } from '../../utils/api'
import Loader from '../../components/Loader'
import Translate from '../../components/Translate'
import SetEditing from '../../components/sets/SetEditing'
import SetDescriptionDialog from '../../components/sets/SetDescrptionDialog'
import SetImageDialog from '../../components/sets/SetImageDialog'
import SetContentContext from '../../contexts/SetContentContext'

function SetFS() {
  const { id } = useParams()
  const api = useApiFetch()
  const [isSubmitting, setisSubmitting] = useStateWithCallback(null)
  const [content, setContent] = useStateWithCallback(null)
  const [error, setError] = useState(null)
  const { t } = useTranslation()
  const contentBoxColor = useColorModeValue('white', 'gray.700')
  const history = useHistory()
  const toast = createStandaloneToast()
  const { isOpen: isDescOpen, onOpen: onDescOpen, onClose: onDescClose } = useDisclosure()
  const { isOpen: isImgOpen, onOpen: onImgOpen, onClose: onImgClose } = useDisclosure()

  useEffect( () => {

    if (id) {
      api(`/admin/sets/${id}`)
        .then((response) => {
          setContent(response)
          setisSubmitting(false)
        })
        .catch(async (err) => {
          setError(err.statusText)
        })
    }
    else {
      setContent({
        title: '',
        active: false,
        public: false,
        pages: [{
          no: 1,
          content: []
        }]
      })
      setisSubmitting(false)
    }
  }, [])

  const isMobile = useBreakpointValue({ base: true, md: false })

  const addPage = () => {
    const { pages } = content

    const prevPageNo = pages[pages.length - 1] && 'no' in pages[pages.length - 1] ? pages[pages.length - 1].no : 0

    setContent({ ...content, pages: [ ...content.pages, { no: prevPageNo + 1, content: [] } ]})
  }

  const handleTitleChange = (title) => { setContent({ ...content, title }) }
  const handlePublicChange = (e) => { setContent({ ...content, public: e.target.checked }) }
  const handleActiveChange = (e) => { setContent({ ...content, active: e.target.checked }) }
  const handleDescriptionChange = (description) => { setContent({ ...content, description }) }
  const handleImageChange = (image) => { setContent({ ...content, image }) }

  const handleEditorUpdate = (no, blocks) => {
    const { pages: newPages } = content

    newPages[no - 1] = { no, content: blocks.map((json) => ({ content: json })) }

    setContent({ ...content, pages: newPages })
  }

  const removePage = (ix) => new Promise((resolve) => {
    let { pages } = content

    delete pages[ix]

    pages = pages.filter((page) => page).map((page, ix) => ({ ...page, no: ix + 1 }))

    setContent({ ...content, pages }, () => {
      resolve(true)
    })
  })

  const handleSubmit = () => {
    setisSubmitting(true)

    const body = new FormData()

    for (let key in content) {
      body.append(key, key === 'image' ? (typeof content[key] === 'string' ? content[key] : content[key].file) : (key === 'pages' ? JSON.stringify(content[key]) : content[key]))
    }

    api(id ? `/admin/sets/${id}` : '/admin/sets', {
      method: id ? 'PUT' : 'POST',
      body
    })
      .then((response) => {
        if (!toast.isActive('create-success')) {
          toast({
            id: 'create-success',
            title: <Translate>{id ? 'Updated!' : 'Created!'}</Translate>,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }

        setContent(response)
        setisSubmitting(false)

        if (!id && response.id) {
          history.replace('/admin/set-editor/' + response.id)
        }
        else if (!id && !response.id) {
          history.replace('/admin/sets')
        }
      })
      .catch((response) => {
        const { status, statusText } = response
        const message = ''
        if (!toast.isActive('response-error')) {

          toast({
            id: 'response-error',
            title: <Translate>{status === 400 ? 'Fix all problems' : 'Unexpected error'}</Translate>,
            description: <Translate>{status === 400 ? message : statusText}</Translate>,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        setisSubmitting(false)
      })
  }

  return (
    !error
      ? <Loader loaded={content && 'pages' in content}>
        {
          content
            ?  <Box w={{ base: '100%', md: '90%', lg: '70%' }} ml={{ base: '0%', md: '5%', lg: '15%' }}>
              <VStack spacing={2}>
                <Flex w='100%' direction={{ base: 'column', md: 'row'}}>
                  <Heading as="h1" size='lg' textAlign={{ base: 'center', md: 'left' }} w='100%'>
                    <Editable placeholder={t('Click to add title...')} defaultValue={content.title} onChange={handleTitleChange}>
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Heading>
                  {
                    !isMobile
                      ? <>
                        <Spacer />
                        <Link to='/admin/sets'>
                          <Button leftIcon={<ArrowBackIcon />} colorScheme="brand" variant="brand-ghost">
                            <Translate>Back</Translate>
                          </Button>
                        </Link>
                      </>
                      : null
                  }
                </Flex>
                <Flex py={4} w='100%'>
                  <FormControl isDisabled={isSubmitting !== false} display="flex" alignItems="center" w='auto' mr={2}>
                    <FormLabel htmlFor="email-alerts" mb="0">
                      <Translate>Published</Translate>
                    </FormLabel>
                    <Switch id="email-alerts" defaultChecked={content.public} colorScheme='brand' onChange={handlePublicChange} />
                  </FormControl>
                  <FormControl isDisabled={isSubmitting !== false} display="flex" alignItems="center" w='auto' mr={2}>
                    <FormLabel htmlFor="email-alerts" mb="0">
                      <Translate>Active</Translate>
                    </FormLabel>
                    <Switch id="email-alerts" defaultChecked={content.active} colorScheme='brand' onChange={handleActiveChange} />
                  </FormControl>
                  <SetEditorContext.Provider value={{ onDescClose, onImgClose, isDescOpen, isImgOpen, handleDescriptionChange, handleImageChange }}>
                    <Button isLoading={isSubmitting !== false} colorScheme="brand" variant="brand-solid" mr={2} onClick={onDescOpen}>
                      { content.description ? content.description.substr(0, 15)+'...' : <Translate>Set description</Translate> }
                    </Button>
                    <Button isLoading={isSubmitting !== false} colorScheme="brand" variant="brand-solid" mr={2} onClick={onImgOpen}>
                      <Translate>{ content.image ? 'Change image' : 'Set image' }</Translate>
                    </Button>
                    <SetDescriptionDialog initialValue={content.description} />
                    <SetImageDialog initialValue={content.image} />
                  </SetEditorContext.Provider>
                  <Spacer />
                  <Button isLoading={isSubmitting !== false} loadingText={isSubmitting === true ? t('Saving') : ''} leftIcon={id ? <MdSave /> : <MdAdd />} colorScheme="brand" variant="brand-solid" onClick={handleSubmit}>
                    <Translate>{ id ? 'Save' : 'Create' }</Translate>
                  </Button>
                </Flex>
              </VStack>
              <Box p={10} borderRadius={'1.25rem'} bgColor={contentBoxColor} shadow='2xl'>
                <SetContentContext.Provider value={{ id, content }}>
                  <SetEditing content={content} addPage={addPage} removePage={removePage} handleUpdate={handleEditorUpdate} />
                </SetContentContext.Provider>
              </Box>
            </Box>
            : null
        }
      </Loader>
      : <Box w={{ base: '100%', md: '90%', lg: '70%' }} ml={{ base: '0%', md: '5%', lg: '15%' }} p={[10]} borderRadius={'1.25rem'} bgColor={contentBoxColor} shadow='2xl'>
        <Heading>
          <Translate>Error loading set</Translate>
        </Heading>
        <Text>{ error }</Text>
      </Box>
  )
}


export default function Set(props) {
  return (
    <React.Suspense fallback='Loading...'>
      <SetFS {...props} />
    </React.Suspense>
  )
}

