import React, { useState, useContext, useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { createStandaloneToast } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import authContext from './contexts/auth'
import { apiFetch } from './api'
import Loader from '../components/Loader'
import Translate from '../components/Translate'

export function Auth({ children }) {

  const [auth, setAuth] = useState({
    token: localStorage.getItem('token') || null
  })
  const [mailToken, setMailToken] = useState(null)

  const updateToken = (token) => setAuth({ ...auth, token,  })
  const handleMailToken = (mailToken) => setMailToken( mailToken )
  const api = apiFetch(auth.token)

  useEffect(() => {
    if (mailToken) {
      const toast = createStandaloneToast()

      api(`/handle-mail-token/${mailToken}`, {
        method: 'POST'
      })
        .then(({ success, title, message }) => {

          if (!toast.isActive('mail-response')) {
            toast({
              id: 'mail-response',
              title: <Translate>{ title }</Translate>,
              description: message ? <Translate>{ message }</Translate> : null,
              status: success ? 'success' : 'error',
              duration: 9000,
              isClosable: true,
            })
          }
        })
        .catch(async (response) => {
          const { title, message } = await response.json()

          if (!toast.isActive('mail-response')) {
            toast({
              id: 'mail-response',
              title: <Translate>{ title }</Translate>,
              description: message ? <Translate>{ message }</Translate> : null,
              status: 'error',
              duration: 9000,
              isClosable: true,
            })
          }
        })
    }
  }, [mailToken])

  return (
    <authContext.Provider value={{ handleMailToken, updateToken, ...auth }}>
      { children }
    </authContext.Provider>
  )
}

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export function AuthorizeRoute({ children, ...otherProps }) {

  const authCtx = useContext(authContext)

  if (otherProps.computedMatch.path === '/e/:token') {
    setTimeout(() => {
      authCtx.handleMailToken(otherProps.computedMatch.params.token)
    }, 500)
  }

  return (
    <Route {...otherProps}>
      {
        authCtx.token
          ? children
          : otherProps.path !== '/grant-access' ? <Redirect to='/sign-in' /> : <Redirect to='/authorize' />
      }
    </Route>
  )
}

AuthorizeRoute.propTypes = {
  children: PropTypes.element
}

export function CheckRoleRoute({ role, children, ...otherProps }) {

  const [isAccessible, setIsAccessible] = useState(null)

  const { checkRole } = useAuth()

  useEffect(() => {
    if (!isAccessible) checkRole(role, function(hasAccess) {
      setIsAccessible(hasAccess)
    })
  }, [isAccessible])

  return (
    <AuthorizeRoute {...otherProps}>
      <Loader loaded={isAccessible !== null}>
        { isAccessible === false ? <Redirect to='/platform' /> : children  }
      </Loader>
    </AuthorizeRoute>
  )
}

CheckRoleRoute.propTypes = {
  children: PropTypes.element,
  role: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
}

export function CheckRole({ role, children, redirect = '/platform' }) {

  const [isAccessible, setIsAccessible] = useState(null)

  const { checkRole } = useAuth()

  useEffect(() => {
    if (!isAccessible) checkRole(role, function(hasAccess) {
      setIsAccessible(hasAccess)
    })
  }, [isAccessible])

  return (
    <Loader loaded={isAccessible !== null}>
      { isAccessible === false ? <Redirect to={redirect} /> : children  }
    </Loader>
  )
}

CheckRole.propTypes = {
  children: PropTypes.element,
  role: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  redirect: PropTypes.string
}

export function NotAuthorizeRoute({ children, ...otherProps }) {

  const authCtx = useContext(authContext)
  const location = useLocation()

  return (
    <Route {...otherProps}>
      {
        !authCtx.token
          ? children
          : otherProps.path === '/authorize' ?
            <Redirect to={{
              pathname: '/grant-access',
              state: { from: location }
            }} />
            :<Redirect to={{
              pathname: '/platform',
              state: { from: location }
            }} />
      }
    </Route>
  )
}

NotAuthorizeRoute.propTypes = {
  children: PropTypes.element
}

export const useAuth = () => {
  const authCtx = useContext(authContext)
  // const history = useHistory()

  return ({
    login: function(token, cb) {
      if (token) {

        authCtx.updateToken(token)

        localStorage.setItem('token', token)

        if (cb) cb()
      }
    },

    logout: function(cb) {

      if (authCtx.token) {

        authCtx.updateToken(null)

        localStorage.removeItem('token')

        if (cb) cb()
      }
    },

    checkRole: function(role, cb) {

      apiFetch(authCtx.token)('/client')
        .then((({ usergroup_shortname = 'client' }) => {
          cb(typeof role === 'string' ? role === usergroup_shortname : role.includes(usergroup_shortname))
        }))
        .catch(() => {})
    },

    auth: authCtx,

    user: function(cb) {

      apiFetch(authCtx.token)('/client')
        .then((({ firstname, lastname, email, usergroup_shortname }) => {
          cb({ firstname, lastname, email, usergroup: usergroup_shortname || 'client' })
        }))
        .catch(({ status }) => {
          if (status === 401) {
            authCtx.updateToken(null)

            localStorage.removeItem('token')

            if (cb) cb()
          }
        })
    }
  })
}
