import React, { useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Textarea, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, AlertDialogFooter, Button } from '@chakra-ui/react'
import Translate from '../Translate'
import SetEditorContext from '../../contexts/SetEditorContext'

function SetDescriptionDialog({ initialValue }) {
  const [value, setValue] = useState(initialValue || '')
  const { isDescOpen: isOpen, onDescClose: onClose, handleDescriptionChange: handleChange } = useContext(SetEditorContext)
  const cancelRef = useRef()

  const handleClick = () => {
    handleChange(value)
    onClose()
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        autoFocus
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Translate>Change description</Translate>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Textarea
                size="sm"
                resize='vertical'
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} ml={3} variant='brand-ghost'>
                <Translate>Cancel</Translate>
              </Button>
              <Button onClick={handleClick}>
                <Translate>Change</Translate>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

SetDescriptionDialog.propTypes = {
  initialValue: PropTypes.string
}

export default SetDescriptionDialog
