import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, Box } from '@chakra-ui/react'

function MyAlert({ type, header, message, children }) {

  const [state, setState] = useState({
    hidden: false
  })

  const closeCall = () => {
    setState({ hidden: true })
  }

  return (
    !state.hidden ?
      <Alert textAlign='left' status={type} variant='left-accent'>
        <AlertIcon />
        <Box flex={1}>
          {
            children
              ? children
              : (
                header
                  ? (
                    <>
                      <AlertTitle mr={2}>{ header }</AlertTitle>
                      <AlertDescription display="block">{ message }</AlertDescription>
                    </>
                  )
                  : null
              )
          }
        </Box>
        <CloseButton onClick={closeCall} position="absolute" right="8px" top="8px" />
      </Alert>
      : null
  )
}

MyAlert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  header: PropTypes.element,
  message: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ])
}

export default MyAlert

