import React from 'react'
import i18next from 'i18next'
import { useAuth } from './auth'
import { createStandaloneToast } from '@chakra-ui/react'
import Translate from '../components/Translate'

export const apiUrl = () =>  process.env.REACT_APP_API || 'http://localhost:8000'

export const apiFetch = (token, logout = () => {}) => (url, options) => {

  return new Promise((resolve, reject) => {

    fetch(apiUrl() + url, { ...options,
      ...{
        headers: {
          ...(options && options.headers ? options.headers : {}),
          'Authorization': 'Bearer ' + (token || localStorage.getItem('token')),
          'LifeSense-Language': i18next.language,
          'User-Agent': 'Life Sense App'
        }
      },
    })
      .then(res => {
        if (!res.ok) return reject(res)

        return res.status === 204 ? new Promise((reso) => { reso([]) }) : res.json()
      })
      .then((res) => resolve(res))
      .catch(err => {
        const id = 'internet-error'
        const toast = createStandaloneToast()

        if (!toast.isActive(id) && err.message === 'Failed to fetch') {
          toast({
            id,
            title: <Translate>Lost connection with server</Translate>,
            description: <Translate>Can&apos;t perform request because you lost internet connection with the server.</Translate>,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })

          logout()
        }
        else {
          return reject(err)
        }
      })
  })
}

export const useApiFetch = () => {
  const { auth, logout } = useAuth()

  return apiFetch(auth.token, logout)
}
