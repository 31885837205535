import React from 'react'

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { AuthorizeRoute, CheckRoleRoute, NotAuthorizeRoute } from './utils/auth'

import Home from './pages/Home'
import Login from './pages/Login'
import GrantAccess from './pages/GrantAccess'

import AdminRouter from './routes-Admin'
import DashboardRouter from './routes-Dashboard'
import DashboardWrapper from './components/dashboard/DashboardWrapper'

import AdminMenu from './components/menus/AdminMenu'
import PlatformMenu from './components/menus/PlatformMenu'

function Router() {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>

        <CheckRoleRoute path='/admin' role={['admin', 'support']}>
          <DashboardWrapper menuSource={<AdminMenu />} routesWithHiddenMenu={[
            '/admin/set-editor'
          ]}>
            <AdminRouter />
          </DashboardWrapper>
        </CheckRoleRoute>

        <AuthorizeRoute path='/platform'>
          <DashboardWrapper menuSource={<PlatformMenu />} routesWithHiddenMenu={[
            '/platform/set/'
          ]}>
            <DashboardRouter />
          </DashboardWrapper>
        </AuthorizeRoute>

        <AuthorizeRoute path='/grant-access'>
          <DashboardWrapper includeMenu={false}>
            <GrantAccess />
          </DashboardWrapper>
        </AuthorizeRoute>

        <AuthorizeRoute path='/e/:token'>
          <DashboardWrapper includeMenu={false}>
            <Redirect to='/platform' />
          </DashboardWrapper>
        </AuthorizeRoute>

        <NotAuthorizeRoute path='/authorize'>
          <Login form='login' authorize={true} />
        </NotAuthorizeRoute>
        <NotAuthorizeRoute path='/sign-in'>
          <Login form='login' authorize={false} />
        </NotAuthorizeRoute>
        <NotAuthorizeRoute path='/sign-up'>
          <Login form='register' />
        </NotAuthorizeRoute>

        <Route path='*'>
          <Redirect to='/platform' />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
