import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, HStack, useColorModeValue } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

import DashboardHeader from './DashboardHeader'
import DashboardMenu from './DashboardMenu'
import { useAuth } from '../../utils/auth'
import DashboardCtx from '../../utils/contexts/dashboard'
import { BasketContextProvider } from '../../utils/contexts/basket'

function DashboardWrapper({ children, includeHeader, includeMenu, menuSource, routesWithHiddenMenu }) {

  const { pathname } = useLocation()
  const { auth } = useAuth()
  const contentBoxColor = useColorModeValue('white', 'gray.700')

  const [drawer, setDrawer] = useState({
    open: false
  })

  const [userMenuOnlyButton, setUserMenuOnlyButton] = useState(false)

  const handleDrawerOpen = () => {
    setDrawer({ ...drawer, open: true })
  }

  const handleDrawerClose = () => {
    setDrawer({ ...drawer, open: false })
  }

  const pathnameFittingRules = routesWithHiddenMenu.map((hiddenRoute) => pathname.substr(0, hiddenRoute.length) === hiddenRoute).includes(true)

  return (
    auth.token ?
      <DashboardCtx.Provider value={{
        handleDrawerClose,
        userMenuOnlyButton: {
          toggle: userMenuOnlyButton,
          setToggle: setUserMenuOnlyButton
        }
      }}>
        <BasketContextProvider>
          <Box py={{ base: 50, lg: 70}} px={{ base: 5, md: 70, lg: 100 }} minH='100vh'>
            { includeHeader ? <DashboardHeader onDrawerOpen={handleDrawerOpen} /> : null }
            { includeMenu && !pathnameFittingRules ?
              <HStack spacing={10} align='flex-start'>
                <DashboardMenu isOpenDrawer={drawer.open} onDrawerClose={handleDrawerClose} source={menuSource} />
                <Box w={'100%'} p={5} borderRadius={'1.25rem'} bgColor={contentBoxColor} shadow='2xl'>{ children }</Box>
              </HStack>
              : children
            }
          </Box>
        </BasketContextProvider>
      </DashboardCtx.Provider>
      : <CircularProgress size='1.4em' color='brand.400' />
  )
}

export default DashboardWrapper

DashboardWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string
  ]),
  includeHeader: PropTypes.bool,
  includeMenu: PropTypes.bool,
  menuSource: PropTypes.element,
  routesWithHiddenMenu: PropTypes.arrayOf(PropTypes.string)
}

DashboardWrapper.defaultProps = {
  includeHeader: true,
  includeMenu: true,
  routesWithHiddenMenu: []
}
