import React, { useState, useEffect } from 'react'
import { Text, Heading, Box, SimpleGrid } from '@chakra-ui/react'

import Loader from '../../components/Loader'
import Translate from '../../components/Translate'
import { useApiFetch } from '../../utils/api'
import RoadmapProduct from '../../components/sets/RoadmapProduct'

function Roadmaps() {

  const [roadmaps, setRoadmaps] = useState(false)

  const api = useApiFetch()

  useEffect(() => {
    if (!roadmaps) {
      api('/roadmaps').then((res) => {
        setRoadmaps(res)
      })
        .catch(() => {})
    }
  }, [])

  return (
    <Box>
      <Loader loaded={!!roadmaps}>

        <Heading as='h2' mb={4}>
          <Translate>Roadmaps</Translate>
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} alignItems='flex-start'>
          {
            roadmaps && roadmaps.length
              ? roadmaps.map(({ id, levels, ...roadmap }) => Object.values(levels).length !== 0
                ? (
                  <RoadmapProduct key={'rmp-'+id} id={id} data={roadmap} levels={levels} />
                )
                : null
              )
              : <Text>
                <Translate>We haven&apos;t published any roadmap yet.</Translate>
              </Text>
          }
        </SimpleGrid>
      </Loader>
    </Box>
  )
}

export default Roadmaps
